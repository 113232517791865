import { extendTheme } from '@chakra-ui/react'

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false
}

const components = {
  //
}

const styles = {
  global: (props) => ({
    'html, body': {
      background: `${props.theme.colors.backgroundColor}`,
      color: `${props.theme.colors.green[500]}`,
      outline: `${props.theme.colors.green[300]} !important`,
      scrollBehavior: 'smooth'
    }
  })
}

const theme = {
  config,
  components,
  styles,
  colors: {
    backgroundColor: '#f5f5f5',
    primary: 'rebeccapurple',
    green: {
      200: '#96AFB8',
      300: '#5B8693',
      500: '#334A52'
    }
  },
}

export default extendTheme(theme)